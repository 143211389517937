import React, { Component } from 'react';

/**
 * En este componente hay props heredados desde un componente Padre
 * bg - portada
 * empresa - empresa del formulario
 */
class FormContacto extends Component {
    render() {
        return(
            <div className="contacto-form" style={{backgroundImage: `url(${this.props.bg})`}}>
                <div className="row justify-content-center no-gutters">
                    <form className="col-12 col-md-10 col-lg-8">
                        <div className="row no-gutters">
                            <div className="col-12 col-md-6 px-3">
                                <input type="hidden" name="empresa" id="empresa" value={this.props.empresa} />
                                <input type="text" name="nombre" id="nombre" placeholder="Nombre" className="form-control mb-4" />
                                <input type="text" name="apellido" id="apellido" placeholder="Apellido" className="form-control mb-4" />
                                <input type="text" name="telefono" id="telefono" placeholder="Teléfono" className="form-control mb-4" />
                                <input type="email" name="correo" id="correo" placeholder="Correo" className="form-control mb-4" />
                                <select name="pais" id="pais" className="form-control mb-4">
                                    <option value="">País</option>
                                    <option value="01">Estados Unidos</option>
                                    <option value="01">Mexico</option>
                                </select>
                            </div>
                            <div className="col-12 col-md-6 px-3">
                                <input type="text" name="empresa" id="empresa" placeholder="Empresa" className="form-control mb-4" />
                                <select name="industria" id="industria" className="form-control mb-4">
                                    <option value="">Industria</option>
                                    <option value="01">Farmaceutica</option>
                                    <option value="01">Hospitalaria</option>
                                </select>
                                <textarea name="mensaje" id="mensaje" placeholder="Mensaje" className="form-control mb-4" cols="30" rows="7"></textarea>
                                <div className="form-group text-center mb-4">
                                    <input className="custom-checkbox " type="checkbox" id="chkbx_2" value="1" />
                                    <label for="chkbx_2" className="text-white">
                                        <span></span> Acepto Términos y Condiciones
                                    </label>
                                </div>
                                <div className="text-center">
                                    <button className="btn btn-style-3">Enviar</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default FormContacto;